// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import moduleSlice from '../views/Setup/ModuleManage/store';
import myCustomer from '../views/MyCustomer/store/index';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    myCustomer
};

export default rootReducer;
