import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCustomer = createAsyncThunk('customerSlice/getUser', async (data) => {
    try {
        showLoader(true);

        let response = await axios.post('/vendorApi/customerController.php', data);

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const createUser = createAsyncThunk('customerSlice/createUser', async (data, { dispatch }) => {
//     try {
//         showLoader(true);

//         let response = await axios.post('/user', data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'User created successfully.');
//         dispatch(getUser());

//         showLoader(false);
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         showLoader(false);
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

// export const updateUser = createAsyncThunk('customerSlice/updateUser', async (data, { dispatch }) => {
//     try {
//         showLoader(true);

//         let response = await axios.put('/user/' + data?.id, data?.data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'User updated successfully.');
//         dispatch(getUser());

//         showLoader(false);
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         showLoader(false);
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

// export const getUserById = createAsyncThunk('customerSlice/getUserById', async (id) => {
//     try {
//         showLoader(true);

//         let response = await axios.get('/user/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });

//         showLoader(false);
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         showLoader(false);
//         toast.error(err.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

// export const deleteUser = createAsyncThunk('customerSlice/deleteUser', async (deleteId, { dispatch }) => {
//     try {
//         showLoader(true);

//         let response = await axios.delete('/user/' + deleteId, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'User deleted successfully');
//         dispatch(getUser());

//         showLoader(false);
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         showLoader(false);
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

// export const updateStatus = createAsyncThunk('customerSlice/updateStatus', async (id, { dispatch }) => {
//     try {
//         showLoader(true);

//         let response = await axios.put('/user/status/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'Status updated successfully.');
//         dispatch(getUser());

//         showLoader(false);
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         showLoader(false);
//         toast.err(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState: {
        data: []
        // options: [],
        // selectedUser: {}
    },
    // reducers: {
    //     handleSelectedUser: (state, action) => {
    //         state.selectedUser = action.payload;
    //     }
    // },
    extraReducers: (builder) => {
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        // builder.addCase(getUserById.fulfilled, (state, action) => {
        //     state.selectedUser = action.payload;
        //     return;
        // });
    }
});

export const { handleSelectedUser } = customerSlice.actions;

export default customerSlice.reducer;
